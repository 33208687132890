import React from 'react';
import '../../../App.css';
import ContentAbout from '../../ContentAbout';
import Footer from '../../Footer';
import { homeObjOne,homeObjFour, homeObjThree, homeObjTwo, homeObjFive } from './Data';
import AboutSection from './AboutSection';


function AboutUs () {
  return (
    <>
      <AboutSection/>
      {/* <div className='about-us'> <h1>Our Purpose</h1> 
      <p>About US & Our Value</p>
      </div> */}
      <ContentAbout  {...homeObjThree}/>
      <ContentAbout  {...homeObjOne}/>
      
      <ContentAbout  {...homeObjTwo}/>
      <ContentAbout  {...homeObjFour}/>
      <ContentAbout  {...homeObjFive}/>
     
    
    </>
  )
}

export default AboutUs

// export default function AboutUs() {
//   return <h1 className='about-us'>LIKE & SUBSCRIBE</h1>;
// }
