import React from 'react';
import '../../../App.css';
import HeroSection from './HeroSection';
import Cards from '../../Cards';
import ContentCompany from './ContentCompany';
import {homeObjOne} from './Data';
import Client from '../../Client';
import Testimoni from '../../Testimoni';
import MarketSegment from './MarketSegment';
import { SegmentOne, SegmentThree, SegmentTwo } from './DataSegment';

function Home() {
  return (
    <>
      <HeroSection/>
      <Cards/>
      <ContentCompany  {...homeObjOne}/>
      <Client/>
      <div className='market-segment'><h1>Our Product Categories</h1></div>
      <MarketSegment {...SegmentOne}/>
      <MarketSegment {...SegmentTwo}/>
      {/* <MarketSegment {...SegmentThree}/> */}
      <div className='quality-img'><h1>QUALITY IS EVERYTHING</h1></div>
      <Testimoni/>
         
    

    </>
  );
}

export default Home;
