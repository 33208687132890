import React from 'react';
import '../../../App.css';
import ContentProduct from '../../ContentProduct';
import { homeObjFive, homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from './Data';
import Footer from '../../Footer';

function Products() {
    return (
      <>
       <div className='products'><h1>PRODUCTS</h1></div>
        <ContentProduct  {...homeObjOne}/>
        <ContentProduct  {...homeObjTwo}/>
        <ContentProduct  {...homeObjThree}/>
        <ContentProduct  {...homeObjFour}/>
        <ContentProduct  {...homeObjFive}/>
        
        

  
      </>
    );
  }

  export default Products;
  