import React from 'react';
import './Testimoni.css';
import TestimoniItem from './TestimoniItem';

function Testimoni() {
  return (
    <div className='testimoni'>
      <h1>Testimoni Toko</h1>
      <div className='testimoni__container'>
        <div className='testimoni__wrapper'>
          <ul className='testimoni__items'>
            <TestimoniItem
              src='images/IDC.jpg'
              text='Wah produk Klickken bagus, semua customer saya pada suka'
              label='Toko Indo Colour Pamulang'
              // path='/products'
            />
            <TestimoniItem
              src='images/sinarsunter.jpg'
              text='Saya sudah pakai auto bright sudah bertahun-tahun, sudah terkenal kualitas bagus & sangat memuaskan'
              label='Toko Cat Sinar Cemerlang Sunter'
              // path='/products'
            />
          {/* </ul>
          <ul className='testimoni__items'> */}
            <TestimoniItem
              src='images/adityajaya.jpg'
              text='Harga dan kualitas Klickken sangat menggiurkan/Bagus
              Lebih banyak Customer saya mencari Klickken'
              label='Toko Aditya Jaya Pandeglang'
              // path='/products'
            />
            <TestimoniItem
              src='images/clingcolour.jpg'
              text='AB & klickken produknya oke banget dan bagus, buat ngaduk dan campur(mixing) itu enak banget. Mantap lah pokoknya'
              label='Toko Cling Colour'
              // path='/products'
            />
            
            {/* <testimoniItem
              src='images/Thinner.png'
              text='Thinner'
              // label='Adrenaline'
              path='/products'
            /> */}
          </ul>
          <ul className='testimoni__items'>
            <TestimoniItem
              src='images/ParayaWarna.jpg'
              text='Sudah pakai Auto Bright dan Klickken mulai dari toko saya buka, tidak pernah mengecewakan'
              label='Toko Paraya Warna'
              // path='/products'
            />
            <TestimoniItem
              src='images/jadicemerlang.jpg'
              text='Alhamdulillah banyak peminatnya, terutama klickken, selain harganya terjangkau daya tutupnya bagus'
              label='Toko Cat Jadi Cemerlang'
              // path='/products'
            />
          {/* </ul>
          <ul className='testimoni__items'> */}
            <TestimoniItem
              src='images/b_cemerlang.jpg'
              text='Proses adjust menggunakan pu autobright sangat bisa diandalkan, mudah di mix. dan standaran PU di banyak tempat'
              label='Toko Barokah Cemerlang'
              // path='/products'
            />
            <TestimoniItem
              src='images/BarokahWarna.jpg'
              text='Cat AB mudah proses mixing dan mencari warna'
              label='Toko Barokah Warna'
              // path='/products'
            />
            
            {/* <testimoniItem
              src='images/Thinner.png'
              text='Thinner'
              // label='Adrenaline'
              path='/products'
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Testimoni;
