import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route, ScrollRestoration } from 'react-router-dom';
import Home from './components/pages/HomePage/Home';
import Services from './components/pages/Services/Services';
import Products from './components/pages/Products/Products';
import AboutUs from './components/pages/AboutUs/AboutUs';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <Router>
        <Navbar/>
        <ScrollToTop/>
        <Routes>
          <Route path='/' exact element={ <Home/>}/>
          {/* <Route path='/services' exact element={ <Services/>}/> */}
          <Route path='/products' exact element={ <Products/>}/>
          <Route path='/about-us' exact element={ <AboutUs/>}/>
        </Routes>
        <Footer/>
    </Router>
    
    
  );
}

export default App;
