export const SegmentOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Berkualitas',
  headline: 'Automotive Refinish',
  description:
    'Lapisan cat otomotif adalah cat dan pelapis yang digunakan  untuk memberikan tampilan segar dan baru sesuai dengan standar  kendaraan pabrik. serta memberikan perlindungan terhadap karat dan korosi. Pengecatan ulang menerapkan berbagai jenis cat. Seperti: Primer, Auto putty, Surfacer,  Basecoat Color, Clearcoat Dan diakhiri pekerjaan akhir berupa Polish.    ',
  buttonLabel: 'Selengkapnya',
  imgStart: 'start',
  img: 'images/2..png',
  alt: 'aboutus'
};

export const SegmentTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: '100% Harga Terbaik',
  headline: 'Industrial Paint',
  description:
    'Pengecatan  pada bagian produk akhir, berupa material dasar: Besi, baja, aluminium, kayu, Plastik, Kertas, Lantai Pemilihan cat untuk proyek industri adalah murni kegunaan, fungsi dan ada kalanya penampilan estetika  menjadi pertimbangan. Cara penggunaan cat bisa dilakukan dengan : Semprot, Kuas, Roller.',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/7..png',
  alt: 'Vault'
};

export const SegmentThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Mudah digunakan',
  headline: 'Decorative Coating',
  description:
    "Metal decoration and light duty protection of metal are mainly used as coating for metal security grill, car park and even roof tile in rural area. The goal is to focus on easiness to use without sacrificing of its competitiveness.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/bridge.png',
  alt: 'Vault'
};

export const SegmentFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
