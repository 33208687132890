import React from 'react';
import './Client.css';
import ClientItem from './ClientItem';

function Client() {
  return (
    <div className='client'>
      <h1>Our Customers</h1>
      <div className='client__container'>
        <div className='client__wrapper'>
          <ul className='client__items'>
            <ClientItem
              src='logo/1.png'
              text='Yogyakarta'
              // label='Adventure'
              // path='/products'
            />
            <ClientItem
              src='logo/2.png'
              text='Pontianak'
              // label='Mystery'
              // path='/products'
            />
            <ClientItem
              src='logo/3.png'
              text='Bandung'
              // label='Luxury'
              // path='/products'
            />
            <ClientItem
              src='logo/4.png'
              text='Bali'
              // label='Mystery'
              // path='/products'
            />
          </ul>
          <ul className='client__items'>
            <ClientItem
              src='logo/5.png'
              text='Medan'
              // label='Mystery'
              // path='/products'
            />
            <ClientItem
              src='logo/6.png'
              text='Riau'
              // label='Adventure'
              // path='/products'
            />
            <ClientItem
              src='logo/7.png'
              text='Jayapura'
              // label='Mystery'
              // path='/products'
            />
            <ClientItem
              src='logo/8.png'
              text='Semarang'
              // label='Mystery'
              // path='/products'
            />
            {/* <ClientItem
              src='images/Thinner.png'
              text='Thinner'
              // label='Adrenaline'
              path='/products'
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Client;
