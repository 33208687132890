import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>PRODUCT HIGHLIGHT!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/particle1.png'
              text='Epoxy'
              // label='Adventure'
              path='/products'
            />
            <CardItem
              src='images/AB-Pu-Clear.png'
              text='PU Clear'
              // label='Adventure'
              path='/products'
            />
            <CardItem
              src='images/Thinner..png'
              text='Thinner PU'
              // label='Luxury'
              path='/products'
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='images/kcs1.png'
              text='NC Solid'
              // label='Luxury'
              path='/products'
            />
            <CardItem
              src='images/ab1.png'
              text='Cat PU'
              // label='Mystery'
              path='/products'
            />
            {/* <CardItem
              src='images/Thinner.png'
              text='Thinner'
              // label='Adrenaline'
              path='/products'
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
