export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'VIEW OUR PRODUCTS',
  headline: 'Epoxy',
  description:
    'Epoxy atau cat dasar terdiri dari dua komponen yaitu base dan hardener. Produk ini digunakan pada permukaan plat besi, kayu atau di atas dempul.',
  buttonLabel: 'Shop Now',
  imgStart: '',
  img: 'produk/Particle.png',
  alt: 'Epoxy'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'VIEW OUR PRODUCTS',
  headline: 'Basecoat PU',
  description:
    'Basecoat (Tinting Colours) digunakan untuk membuat warna menjadi sesuai dengan warna yang diinginkan.',
  buttonLabel: 'Shop Now',
  imgStart: '',
  img: 'produk/AB.png',
  alt: 'Basecoat AB'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'EASY SETUP',
  headline: 'Basecoat NC',
  description:
    "Basecoat (Tinting Colours) digunakan untuk membuat warna menjadi sesuai dengan warna yang diinginkan.",
  buttonLabel: 'Shop Now',
  imgStart: 'start',
  img: 'produk/KCS.png',
  alt: 'Klickken NC'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'PU Clear',
  description:
    'Pernis (Clear Coat) tampilan gloss (kilap) terdiri dari dua komponen (Base dan Hardener). Produk ini digunakan untuk melindungi cat dari sinar matahari langsung (anti UV) agar tidak cepat pudar serta membuat tampilan cat lebih mengkilap.',
  buttonLabel: 'Shop Now',
  imgStart: 'start',
  img: 'produk/Clear.png',
  alt: 'PU Clear AB'
};

export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'DATA ANALYTICS',
  headline: 'Thinner',
  description:
    'Thinner dapat digunakan sebagai pengencer epoxy, cat, dan pernis.',
  buttonLabel: 'Shop Now',
  imgStart: 'start',
  img: 'produk/Thinner.png',
  alt: 'Thinner AB'
};
