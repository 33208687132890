import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';


function Navbar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const [button, setButton] = useState(true);
    const showButton = () => {
        if (window.innerWidth <= 960) {
          setButton(false);
        } else {
          setButton(true);
        }
      };

      useEffect(() => {
        showButton();
      }, []);

      window.addEventListener('resize', showButton);
  return (
    <>
        <nav className="navbar">
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    <img src='images/Logo-DKP1.png' width="70" height="auto" className="navbar-icon" />
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                     <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                 </div>
                 <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
           
            

            <li>
              <Link
                to='/about-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About Us
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='https://produk.dayakemindoperkasa.com/'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Products
              </Link>
            </li>

            {/* <li className='nav-item'>
              <Link
                to='/products'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Products
              </Link>
            </li> */}

          </ul>
          {/* {button && <Button buttonStyle='btn--outline'>Products</Button>} */}
            </div>
        </nav>
    </>
  )
}

export default Navbar