export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: 'Exclusive Access',
  headline: 'Our Values',
  description:
    'PT Daya Kemindo Perkasa selalu mengedepankan kepuasan pelanggan dalam hal kualitas dan mutu, selain kualitas dan mutu, kami jg menjaga service kami ke pelanggan dengan cara memberikan distribusi yg terbaik ke pelanggan.',
  // buttonLabel: 'Selengkapnya',
  imgStart: '',
  img: 'media/value.png',
  alt: 'Value'
};


export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'EASY SETUP',
  headline: 'Our Company',
  description:
    "PT Daya Kemindo Perkasa adalah salah satu perusahaan yang bergerak di industry cat,thinner dan solvent di Indonesia. Perusahaan yang didirikan pada tahun 2001 ini telah berkembang pesat menjadi perusahaan handal yang mengaplikasikan teknologi paling efisien pada proses produksi dan menawarkan produk-produk berkualitas kepada pelanggan.",
  // buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/aboutus.jpg',
  alt: 'Vault'
};


export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  // topLine: '100% SECURE',
  headline: 'Our Vision',
  description:
    '1. Menjadi salah satu produsen cat, thinner dan solvent terkemuka di Indonesia yang memiliki share yang signifikan dan memegang peranan penting di pasar lokal dan internasional.',
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'media/QC1.png',
  alt: 'Vault'
};



export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: 'DATA ANALYTICS',
  // headline: 'Every transaction is stored on our secure cloud database',
  description:
    '2. Menciptakan Kerjasama dan menjamin hasil yang saling menguntungkan diantara rekanan dan pelanggan.',
  // buttonLabel: 'Sign Up Now',
  imgStart: '',
  img: 'media/rekanan.png',
  alt: 'Vault'
};


export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  // topLine: 'DATA ANALYTICS',
  // headline: 'Every transaction is stored on our secure cloud database',
  description:
    '3. Secara berkelanjutan mengembangkan spesifikasi produk yang akan memenuhi kebutuhan dan kepuasan pelanggan',
  // buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'media/rptcst.png',
  alt: 'Vault'
};
